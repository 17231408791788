import { reactive, ref, toRefs } from 'vue';
type FormData = {
  consigneeNo: string | null;
  consigneeType: string | number | null;
  fullName: string | null;
  id: string | number;
  invoiceInfo: string | null;
  isManageLicense: boolean;
  recordNumber: string | number;
  remark: string | null;
  shortName: string | null;
  version: string | number;
  isRequireOrigin: boolean;
  userIds: any[];
  userNames: string | null;
  customerId: string | null;
  customerName?: string | null;
  [propName: string]: any;
};
export default () => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    consigneeNo: null,
    consigneeType: null,
    fullName: null,
    id: '',
    invoiceInfo: '',
    isManageLicense: false,
    recordNumber: '',
    remark: '',
    shortName: '',
    version: '',
    isRequireOrigin: true,
    userIds: [],
    userNames: null,
    customerId: null,
  });
  const customFormData = reactive({
    isDisabled: false,
  });
  const rules = reactive({
    shortName: [
      {
        required: true,
        message: '请输入抬头',
        trigger: 'blur',
      },
    ],
    fullName: [
      {
        required: true,
        message: '请输入抬头全称',
        trigger: 'blur',
      },
    ],
    isManageLicense: [
      {
        required: true,
        message: '请选择是否管理批文',
        trigger: 'change',
      },
    ],
    isRequireOrigin: [
      {
        required: true,
        message: '请选择抬头是否要求正本文件',
        trigger: 'change',
      },
    ],
    userIds: [
      {
        required: true,
        message: '请选择单证对接人',
        trigger: 'blur',
      },
    ],
    consigneeType: [
      {
        required: true,
        message: '请选择抬头类型',
        trigger: 'change',
      },
    ],
    customerId: [
      {
        required: true,
        message: '请选择客户',
        trigger: 'change',
      },
    ],
  });
  const changeShortName = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z\d\u4E00-\u9FA5]/g, ''); // 清除中文英文以外的字符
    formData[prop] = value;
  };

  const changeRecordNumber = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符
    formData[prop] = value;
  };
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    changeShortName,
    changeRecordNumber,
  };
};
