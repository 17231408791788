<template>
  <CrmFormLayout title="查看抬头">
    <div class="view-rise-page">
      <div class="view__form view__rise">
        <div class="view__group">
          <label class="view__label">抬&nbsp;&nbsp;&nbsp;头</label>
          <div class="view__input">{{ data.shortName }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">抬头全称</label>
          <div class="view__input">
            <el-tooltip
              effect="dark"
              :content="data.fullName"
              placement="top-start"
              :disabled="data.fullName && data.fullName.length <= 30"
              class="tootip-over"
            >
              <div>{{ data.fullName }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="view__group">
          <label class="view__label">抬头类型</label>
          <div class="view__input">{{ data.consigneeTypeName }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">是否管理批文</label>
          <div class="view__input">{{ data.isManageLicenseName }}</div>
        </div>
        <div class="view__group">
          <label class="view__label break">抬头是否要求正本文件</label>
          <div class="view__input">
            {{ data.isRequireOriginName }}
          </div>
        </div>
        <div class="view__group">
          <label class="view__label">单证对接人</label>
          <div class="view__input">
            <el-tooltip
              effect="dark"
              :content="data.userNames"
              placement="top-start"
              :disabled="data.userNames && data.userNames.length <= 30"
              class="tootip-over"
            >
              <div>{{ data.userNames }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="view__group">
          <label class="view__label">备案号</label>
          <div class="view__input">{{ data.recordNumber }}</div>
        </div>
        <div class="view__group" v-if="data.consigneeType === 2">
          <label class="view__label">客户</label>
          <div class="view__input">
            <el-tooltip
              effect="dark"
              :content="data.customerName"
              placement="top-start"
              :disabled="data.customerName && data.customerName.length <= 19"
              class="tootip-over"
            >
              <div>{{ data.customerName }}</div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <el-form>
        <div class="mali-edit__form label-con" style="padding-top: 10px">
          <el-form-item label="抬头备注">
            <div class="view__area">{{ data.remark }}</div>
          </el-form-item>
        </div>
        <div class="mali-edit__form label-con" style="padding-top: 10px">
          <el-form-item label="开票信息">
            <div class="view__area">{{ data.invoiceInfo }}</div>
          </el-form-item>
        </div>
      </el-form>
      <div class="crm-button__group">
        <button class="crm-form__button primary" @click="close(false)">关闭</button>
      </div>
    </div>
  </CrmFormLayout>
</template>

<script>
export default {
  components: {},
  props: ['rowData'],
  created() {
    this.getinitData(this.rowData.id);
  },
  data() {
    return {
      data: {
        consigneeNo: '',
        consigneeType: '',
        consigneeTypeName: '',
        createdTime: '',
        creator: '',
        fullName: '',
        id: 0,
        invoiceInfo: '',
        isManageLicense: false,
        isManageLicenseName: '',
        recordNumber: '',
        remark: '',
        shortName: '',
        version: 0,
      },
    };
  },
  methods: {
    getinitData(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/consignee/consigneeDetail/' + id,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              const data = res.result;
              for (const key in data) {
                if (data[key] === '' || data[key] === null) {
                  data[key] = '-';
                }
              }
              this.data = data;
            }
          }
        },
      });
    },
    close() {
      this.$emit('close', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.view__form {
  padding: 24px 20px 4px;
  border: 1px solid #e3e4e6;
  background: #fff;
  overflow: hidden;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>
