import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f7d7fa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "flex-box" }
const _hoisted_3 = { class: "mali-edit__form" }
const _hoisted_4 = { class: "mali-edit__form" }
const _hoisted_5 = {
  key: 0,
  class: "crm-button__group"
}
const _hoisted_6 = {
  key: 1,
  class: "crm-button__group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_DictSelect = _resolveComponent("DictSelect")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ChooseMan = _resolveComponent("ChooseMan")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['新建', '编辑']) + '抬头',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "抬头",
                      prop: "shortName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          maxlength: 8,
                          modelValue: _ctx.formData.shortName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.shortName) = $event)),
                          modelModifiers: { trim: true },
                          clearable: "",
                          placeholder: "请输入抬头",
                          disabled: _ctx.formType === 2,
                          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeShortName($event, 'shortName')))
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "抬头全称",
                      prop: "fullName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          maxlength: 100,
                          modelValue: _ctx.formData.fullName,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.fullName) = $event)),
                          modelModifiers: { trim: true },
                          clearable: "",
                          placeholder: "请输入抬头全称"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "抬头类型",
                      prop: "consigneeType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.isDisabled,
                          modelValue: _ctx.formData.consigneeType,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.consigneeType) = $event)),
                          name: "consignee_type",
                          placeholder: "请选择抬头类型",
                          onChange: _ctx.changeConsigneeType
                        }, null, 8, ["disabled", "modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "是否管理批文",
                      prop: "isManageLicense"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          class: "mali-full__input",
                          modelValue: _ctx.formData.isManageLicense,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.isManageLicense) = $event)),
                          clearable: "",
                          placeholder: "请选择是否管理批文",
                          disabled: _ctx.isDisabled
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              label: "是",
                              value: true
                            }),
                            _createVNode(_component_el_option, {
                              label: "否",
                              value: false
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "抬头是否要求正本文件",
                      prop: "isRequireOrigin"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          class: "mali-full__input",
                          modelValue: _ctx.formData.isRequireOrigin,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.isRequireOrigin) = $event)),
                          clearable: "",
                          placeholder: "请选择抬头是否要求正本文件"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              label: "是",
                              value: true
                            }),
                            _createVNode(_component_el_option, {
                              label: "否",
                              value: false
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "单证对接人",
                      prop: "userIds"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_ChooseMan, {
                            titleLabel: "单证对接人",
                            onClose: _ctx.chooseManClose,
                            modelValue: _ctx.formData.userIds,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.userIds) = $event)),
                            customOptionLabel: "单证部",
                            placeholder: "请选择单证对接人",
                            name: _ctx.formData.userNames,
                            "onUpdate:name": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.userNames) = $event)),
                            defaultCreated: false,
                            limt: 3,
                            ref: "principalRef",
                            style: {"width":"420px"}
                          }, null, 8, ["onClose", "modelValue", "name"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "备案号",
                      prop: "recordNumber"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          maxlength: 30,
                          modelValue: _ctx.formData.recordNumber,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.recordNumber) = $event)),
                          onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeRecordNumber($event, 'recordNumber'))),
                          placeholder: "请输入备案号",
                          clearable: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _withDirectives(_createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "客户",
                      prop: "customerId",
                      rules: 
                _ctx.formData.consigneeType === 2
                  ? [
                      {
                        required: true,
                        message: '请选择客户',
                        trigger: 'change',
                      },
                    ]
                  : []
              
                    }, {
                      default: _withCtx(() => [
                        (_ctx.formType < 3)
                          ? (_openBlock(), _createBlock(_component_el_select, {
                              key: 0,
                              modelValue: _ctx.formData.customerId,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.customerId) = $event)),
                              clearable: "",
                              filterable: "",
                              class: "mali-full__input",
                              placeholder: "请选择客户",
                              remote: "",
                              "reserve-keyword": "",
                              "remote-method": _ctx.searchCustomer,
                              onBlur: _ctx.changeCustomer,
                              "suffix-icon": "el-icon-arrow-down"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerOptions, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.id,
                                    label: item.customerName,
                                    value: item.id
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "remote-method", "onBlur"]))
                          : (_openBlock(), _createBlock(_component_el_input, {
                              key: 1,
                              modelValue: _ctx.formData.customerName,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.customerName) = $event)),
                              disabled: true
                            }, null, 8, ["modelValue"]))
                      ]),
                      _: 1
                    }, 8, ["rules"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.formData.consigneeType === 2]
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "抬头备注",
                      prop: "remark",
                      class: "form-item__label"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.remark,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formData.remark) = $event)),
                          placeholder: "请输入抬头备注",
                          maxlength: 300,
                          class: "mali-full__input",
                          type: "textarea",
                          resize: "none",
                          rows: 5
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "开票信息",
                      prop: "invoiceInfo",
                      class: "form-item__label"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.invoiceInfo,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formData.invoiceInfo) = $event)),
                          placeholder: "请输入开票信息",
                          maxlength: 300,
                          class: "mali-full__input",
                          type: "textarea",
                          resize: "none",
                          rows: 5
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      (_ctx.formType < 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "crm-form__button primary",
              onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
            }, "保存"),
            (_ctx.formType === 2)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "crm-form__button cancel",
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.close(false)))
                }, "取消"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.formType >= 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "crm-form__button primary",
              onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.close(false)))
            }, "关闭")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}