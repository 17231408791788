import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, successMessage, errorMessage } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'shortName',
      value: '',
      placeholder: '请输入抬头',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '抬头',
      prop: 'shortName',
      minWidth: 154,
    },
    {
      label: '抬头全称',
      prop: 'fullName',
      minWidth: 260,
    },
    {
      label: '抬头类型',
      prop: 'consigneeTypeName',
      minWidth: 120,
    },
    {
      label: '抬头是否要求正本文件',
      prop: 'isRequireOriginName',
      minWidth: 210,
    },
    {
      label: '备案号',
      prop: 'recordNumber',
      minWidth: 150,
    },
    {
      label: '抬头备注',
      prop: 'remark',
      minWidth: 280,
    },
    {
      label: '单证对接人',
      prop: 'userNames',
      minWidth: 168,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 143,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 184,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-permission="['crm:consignee:operateConsignee']" type="primary" plain @click="edit(row)">编辑</el-button>
            <el-button v-permission="['crm:consignee:deleteConsignee']" type="info" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/consignee/deleteConsignee', {
                  id: row.id,
                  version: row.version,
                });
                if (res.code === 200) {
                  successMessage('已删除');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      fixed: 'right',
      width: 200,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
