
import { httpGet, httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref, computed } from 'vue';
import useFormData from './useRiseFormData';
export default defineComponent({
  components: { ChooseMan },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const { rules, formData, formRef, setFormData, changeShortName, isDisabled, changeRecordNumber } = useFormData();
    const principalRef = ref();
    const customerOptions = ref([]);
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    // 单证对接人组件对象

    const changeConsigneeType = (value: number) => {
      // 切换客户名称动态校验
      if (value === 2) {
        formRef.value.validateField('customerId');
      } else {
        formData.customerId = null;
        formData.customerName = null;
        formRef.value.clearValidate('customerId');
      }
    };
    const searchCustomer = async (name: string) => {
      const params = {
        customerName: name,
      };
      const res = await httpPost('/malicrm/business/selectCustomer', params);
      if (res.code === 200) {
        customerOptions.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    // 默认回填搜索
    const changeCustomer = () => {
      if (customerOptions.value.length <= 0) {
        searchCustomer('');
      }
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const chooseManClose = () => {
      formRef.value.validateField('userIds');
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/consignee/operateConsignee', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('保存成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };
    const checkForEdit = async (id) => {
      const params = {
        id,
      };
      const res = await httpPost('/malicrm/consignee/checkForEdit', params);
      if (res.code === 200) {
        isDisabled.value = !res.result;
      } else {
        errorMessage(res.message);
      }
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/consignee/consigneeDetail/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        searchCustomer(res.result.customerName);
      } else {
        errorMessage(res.message);
      }
    };
    if (props.formType > 1) {
      const id = (props.data as any).id;
      checkForEdit(id);
      initData(id);
    } else {
      // 进来先调用一次获取客户
      changeCustomer();
    }
    return {
      customerOptions,
      changeCustomer,
      searchCustomer,
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      principalRef,
      initData,
      readOnly,
      changeShortName,
      isDisabled,
      changeRecordNumber,
      changeConsigneeType,
      checkForEdit,
      chooseManClose,
    };
  },
});
